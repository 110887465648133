<template>
  <div
    ref="dotCard"
    class="collection-dot-product"
    :style="`left: ${position.x}%; top: ${position.y}%`"
    :class="classNameListRoot"
    @mouseover="getProduct"
  >
    <div
      v-if="position.x > 0"
      class="collection-dot-product__pin"
    ></div>
    <div
      class="collection-dot-product__card"
      :class="{'--loading': isLoadProduct}"
    >
      <template v-if="product">
        <div class="collection-dot-product__card-name h6">{{ product.name }}</div>
        <div class="collection-dot-product__card-footer">
          <div class="collection-dot-product__card-price h5 semibold">
            {{ $numberFormat(product?.price || 0) }} ₽
          </div>
          <div
            v-if="isVisibleBuy"
            class="collection-dot-product__card-buy"
            @click="addToCartProduct"
          >
            <img src="~/assets/img/svg/common/shopping.svg" />
          </div>
        </div>
      </template>
      <template v-if="isLoadProduct">
        <img src="~/assets/img/svg/loading/body-loading.svg" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type ComputedRef } from 'vue';
import collectionImageSizeAdmin from '~/constants/collection-image-size-admin.json';
import { useCommonStore } from '~/store/common';
import useCart from '#sitis/composables/useSitisCart';

const props = defineProps({
  info: {
    type: Object as any,
    default: () => {}
  }
});
const {
  $api,
  $toast,
  $numberFormat
} = useNuxtApp();
const cartStore = useCart();
const commonStore = useCommonStore();

const dotCard = ref<HTMLDivElement | null>(null);
const product: any = ref(null);
const isLoadProduct = ref(true);
const isVisibleBuy = computed(() => {
  if (product.value?.has_options && !props?.info?.option) {
    return false;
  }
  if (product.value?.has_options) {
    const productOptions = product?.value?.options || [];

    let countAvailableOption = 0;
    Object.keys(props?.info?.option || {}).map((optionKey: string) => {
      const optionValue: any = props?.info?.option[optionKey];
      const isAvailableValue = Boolean((productOptions.find((t: any) => String(t.id) === String(optionKey))?.variants || []).find((t: any) => String(t.id) === String(optionValue)));
      if (isAvailableValue) {
        countAvailableOption = countAvailableOption + 1;
      }
    });

    return Boolean(countAvailableOption === Object.keys(props?.info?.option || {}).length);
  }
  return true;
});
const getProduct = async () => {
  if (!isLoadProduct.value || product.value?.id) {
    return;
  }
  const fields = ['id', 'name', 'price', 'price_old', 'image_id', 'has_options'];
  const _product = await $api.agent.get(`/products/${props.info.product_id}?noPushRecent=1&fields=${fields.join(',')}`).then((res: any) => {
    return res?._data;
  }).catch(() => {
    return null;
  });
  if (!_product) {
    position.value = { x: 0, y: 0 };
    return;
  }

  product.value = _product;
  isLoadProduct.value = false;
};
const addToCartProduct = async () => {
  commonStore.changeLoading(true);

  const options = props?.info?.option || {};

  const body: any = {
    productId: props.info?.product_id,
    quantity: 1
  };
  if (Object.keys(options).length > 0) {
    body.options = {};

    Object.keys(options).forEach((optionId: any) => {
      body.options[optionId] = options[optionId];
    });
  }

  const resAdd: any = await cartStore.addToCart(body).catch((err) => {
    return { error: err._data };
  });

  if (resAdd?.error) {
    commonStore.changeLoading(false);
    let errorMessage = (resAdd?.error || []).map((t: any) => t.message).join(', ');
    if (!errorMessage) {
      errorMessage = 'Ошибка сервера';
    }
    $toast.error(errorMessage);
    return;
  }

  await cartStore.getCart();
  commonStore.changeLoading(false);
  $toast.success('Товар добавлен в корзину');
};

const position = ref({ x: 0, y: 0 });
const setPosition = () => {
  const isPercentVersion = true;

  const parent = dotCard?.value?.parentElement || null;

  if (parent) {
    const parentSize = parent?.getBoundingClientRect();

    const differenceWidth = (100 * (parentSize.width - collectionImageSizeAdmin.width) / collectionImageSizeAdmin.width);
    const differenceHeight = (100 * (parentSize.height - collectionImageSizeAdmin.height) / collectionImageSizeAdmin.height);

    const positionTopPx = props?.info?.top + ((props?.info?.top / 100) * differenceHeight);
    const positionLeftPx = props?.info?.left + ((props?.info?.left / 100) * differenceWidth);

    const positionTopPercent = isPercentVersion ? props?.info?.top : (positionTopPx / parentSize.height) * 100;
    const positionLeftPercent = isPercentVersion ? props?.info?.left : (positionLeftPx / parentSize.width) * 100;

    position.value = { x: positionLeftPercent, y: positionTopPercent };

    return;
  }
  position.value = { x: 0, y: 0 };
};

const classNameListRoot = computed((): string => {
  const list: string[] = [];

  if (!positionDotCard.value.isLeft) {
    list.push('--right');
  }
  if (!positionDotCard.value.isBottom) {
    list.push('--top');
  }

  return list.join(' ');
});

const positionDotCard = ref({ isLeft: true, isBottom: true });
const setPositionDotCard = () => {
  if (dotCard && dotCard.value) {
    const parentNode = dotCard.value;

    const parentNodeBound = parentNode.getBoundingClientRect();

    const dotNode = dotCard.value.querySelector('.collection-dot-product__pin');
    const dotNodeBound = dotNode?.getBoundingClientRect();

    if (dotNodeBound) {
      const isLeft: boolean = Boolean((dotNodeBound.left + 180) < parentNodeBound.right);
      const isBottom: boolean = Boolean((dotNodeBound.bottom - 100) > parentNodeBound.top);

      positionDotCard.value = {
        isLeft,
        isBottom
      };
    }
  }
};
onMounted(() => {
  setPosition();
  setPositionDotCard();
});
</script>

<style scoped lang="scss">
.collection-dot-product {
	position: absolute;
	z-index: 11;

	&:hover {
		z-index: 12;

		.collection-dot-product__card {
			display: block;
		}
	}

	&.--top {
		.collection-dot-product__card {
			bottom: initial;
			top: 20px;
			border-radius: 0 10px 10px 10px;

			&:before {
				top: -6px;
				transform: scaleX(1) scaleY(-1);
			}

			&:after {
				top: -20px;
			}
		}
	}

	&.--right {
		.collection-dot-product__card {
			left: initial;
			right: 50%;
			border-radius: 10px 10px 0 10px;

			&:before {
				transform: scaleX(-1) scaleY(1);
				left: initial;
				right: 0;
			}

			&:after {
				left: initial;
				right: 0;
			}
		}
	}

	&.--right.--top {
		.collection-dot-product__card {
			border-radius: 10px 0 10px 10px;

			&:before {
				top: -6px;
				left: initial;
				right: 0;
				transform: scaleX(-1) scaleY(-1);
			}

			&:after {
				top: -20px;
				left: initial;
				right: 0;
			}
		}
	}
}

.collection-dot-product__pin {
	width: 9px;
	height: 9px;
	border-radius: 100%;
	background-color: white;
	cursor: pointer;

	&:after {
		content: "";
		width: 5px;
		height: 5px;
		position: absolute;
		top: 2px;
		left: 2px;
		border-radius: 100%;
		background-color: #404040;
	}
}

.collection-dot-product__card {
	width: 100vw;
	max-width: 180px;
	display: none;
	position: absolute;
	left: 50%;
	bottom: 20px;
	padding: 10px 16px;
	box-sizing: border-box;
	border-radius: 10px;
	border-bottom-left-radius: 0;
	background-color: white;

	&.--loading img {
		width: 32px;
		height: 32px;
		display: block;
		object-fit: contain;
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: calc(100% - 10px);
		height: 30px;
		width: 30px;
	}

	&:before {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		display: block;
		width: 14px;
		height: 6px;
		background-size: contain;
		background-image: url("@/assets/img/svg/additionally/tooltip-circle-bottom-left.svg");
		filter: invert(1);
	}
}

.collection-dot-product__card-name {
	margin-bottom: 20px;
	color: #333333;
	line-height: 120%;
}

.collection-dot-product__card-price {
	margin-right: auto;
}

.collection-dot-product__card-buy {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	background-color: #404040;
	cursor: pointer;

	img {
		filter: invert(1);
		width: 14px;
		height: 14px;
	}

	&:hover {
		background-color: #2F2E2E;
	}
}

.collection-dot-product__card-footer {
	display: flex;
	align-items: center;
}
</style>
